import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ProfileType } from '@dltru/dfa-models'
import {
    Box,
    CopyButton,
    LockIcon,
    PageHeader,
    Space,
    Tabs,
    UserOutlined,
    getFullName,
} from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'
import { setUIState } from '@store/ui'

import { OtherDfaTab } from '@pages/ClientCard/MyProfile/components/OtherDfaTab'

import { Balance } from './components/Balance/Balance'
import { DfasTab } from './components/DfasTab'
import { OrdersTab } from './components/OrdersTab'
import { ProfileTab } from './components/ProfileTab'
import { QualificationTab } from './components/QualificationTab'
import { TaxesTab } from './components/Taxes'
import { Wallet } from './components/Wallet'

type StateType = {
    tabKey: string
}

export const MyProfile: FC = () => {
    const reduxDispatch = useDispatch()
    const isEmitter = useSelector(profileSelector.isEmitter)
    const isUserPRSN = useSelector(profileSelector.selectProfileTypes)?.includes(ProfileType.PRSN)
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state as StateType
    const [activeTab, setActiveTab] = useState(state?.tabKey)
    const profile = useSelector(profileSelector.selectProfile)

    const isPartiallyBlocked = useSelector(profileSelector.selectIsUserBlocked)
    const userID = useSelector(authSelector.selectUserUid)

    useEffect(() => {
        if (state?.tabKey) {
            setActiveTab(state.tabKey)
        }
    }, [state?.tabKey])

    useEffect(() => {
        reduxDispatch(profileDetailsSlice.actions.getApprovalHistory())
        reduxDispatch(profileDetailsSlice.actions.getQualifyInfo())
        return () => {
            reduxDispatch(setUIState({ clientCurrentTab: null }))
        }
    }, [])

    const onChangeTab = (tabName: string) => {
        setActiveTab(tabName)
        navigate(location.pathname, {})
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={
                        <Space>
                            {isPartiallyBlocked ? (
                                <LockIcon width={26} height={26} />
                            ) : (
                                <UserOutlined />
                            )}
                            <span>{getFullName(profile)}</span>
                        </Space>
                    }
                    subTitle={
                        <>
                            ID {userID} {userID && <CopyButton text={userID} />}
                        </>
                    }
                />
            </Box>
            <Tabs activeKey={activeTab} onChange={onChangeTab}>
                <Tabs.TabPane tab="Профиль" key="profile">
                    <ProfileTab />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Лицевой счёт" key="account">
                    <Balance />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Кошелёк" key="wallet">
                    <Wallet />
                </Tabs.TabPane>
                {isEmitter && (
                    <Tabs.TabPane tab="Мои выпуски" key="dfas">
                        <DfasTab />
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="Мои заявки" key="orders">
                    <OrdersTab />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Иные сделки с ЦФА" key="other">
                    <OtherDfaTab />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Квалификация" key="qualification">
                    <QualificationTab />
                </Tabs.TabPane>
                {isUserPRSN && (
                    <Tabs.TabPane tab="Налоги" key="taxes">
                        <TaxesTab />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </>
    )
}
