import { Bill, DownloadButton } from '@dltru/dfa-ui'
import { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'

import style from './style.m.less'
import { StatementModal } from './Statement'

interface IComponentProps {
    onClick?: () => void
}

export const BalanceListHeader: FC<IComponentProps> = () => {
    const [isShowModal, setIsShowModal] = useState(false)
    const moneyAccount = useSelector(moneySelector.selectAccount)

    const onCloseHandler = useCallback(() => {
        setIsShowModal(false)
    }, [])

    const onClickHandler = useCallback(() => {
        setIsShowModal(true)
    }, [])

    if (!moneyAccount?.id) {
        return null
    }

    return (
        <>
            <div className={style.BalanceListHeader}>
                <div className={style.LeftWrapper}>
                    <div className={style.BalanceListTitle}>История операций</div>
                </div>
                <div className={style.RightWrapper}>
                    <DownloadButton
                        icon={<Bill />}
                        title="Сформировать выписку"
                        onClick={onClickHandler}
                    />
                </div>
            </div>
            {isShowModal && (
                <StatementModal
                    account_id={moneyAccount.id}
                    title={'Выписка по операциям'}
                    onClose={onCloseHandler}
                />
            )}
        </>
    )
}
