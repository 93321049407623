import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { Box } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'

import { AgreementsForm } from '@pages/Login/components/AgreementsForm'

import { AuthSideBox } from '@components/SideBox'

export const Agreements: FC = () => {
    const isAllAgreements = useSelector(authSelector.isAllAgreementsSelector)

    if (isAllAgreements) {
        return <Navigate to={'/'} replace />
    }

    return (
        <Box direction="row" align="center" justify="center" height="100%">
            <Box direction="row">
                <AuthSideBox />
                <AgreementsForm />
            </Box>
        </Box>
    )
}
