import { DocumentsEnum, DocumentReportTypeEnum } from '@dltru/dfa-models'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Form,
    FormItem,
    MiniModal,
    Moment,
    Space,
    WrappedDateRangeItem,
} from '@dltru/dfa-ui'
import { documentsSlice } from '@store/documents'
import moment from 'moment'
import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'

interface IComponentProps {
    onClose: () => void
    title: string
    account_id?: string
}

interface IDocumentGenerator {
    document_name: DocumentsEnum
    period: Moment[]
    show_requisites: boolean
}
export const StatementModal: FC<IComponentProps> = ({ onClose, title, account_id }) => {
    const reduxDispatch = useDispatch()

    const [form] = Form.useForm()

    const onFinishHandler = useCallback((values: IDocumentGenerator) => {
        const { period, show_requisites } = values

        reduxDispatch(
            documentsSlice.actions.downloadDocument({
                document_name: DocumentsEnum.nominal_account_operations_history,
                params: {
                    start_date: moment(period[0]).format(),
                    end_date: moment(period[1]).format(),
                    show_requisites,
                    report_type: DocumentReportTypeEnum.specific_account,
                    account_id: account_id,
                },
            }),
        )
        onClose()
    }, [])

    return (
        <MiniModal
            visible
            onCancel={onClose}
            destroyOnClose
            title={<Space size={16}>{title}</Space>}
            width={680}
            closable
            footer={[
                <Button borderRadius={12} onClick={onClose}>
                    Отмена
                </Button>,
                <Button type="primary" borderRadius={12} onClick={form.submit}>
                    Сформировать выписку
                </Button>,
            ]}
        >
            <Box margin={[24, 0, 0, 0]}>
                <Box>Для формирования выписки укажите необходимые параметры.</Box>
                <Divider margin={[16, 0]} />
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        document_name: account_id
                            ? DocumentsEnum.user_account_operations_history
                            : DocumentsEnum.nominal_account_operations_history,
                        show_requisites: false,
                    }}
                    onFinish={onFinishHandler}
                >
                    <Form.Item name="document_name" hidden />

                    <WrappedDateRangeItem
                        onlyPast
                        name="period"
                        style={{ width: '100%' }}
                        label="Период"
                        required
                    />
                    <FormItem name="show_requisites" valuePropName="checked">
                        <Checkbox>Показывать реквизиты внешних счетов</Checkbox>
                    </FormItem>
                </Form>
            </Box>
        </MiniModal>
    )
}
