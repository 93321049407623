import { ProfileType, ServiceCodeFee, getPennies, stringToNumber } from '@dltru/dfa-models'
import {
    Box,
    Button,
    CurrencyInput,
    Form,
    Modal,
    MoneyAccountBlock,
    moneyValidators,
    useDebounce
} from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCalculateFeeHelper } from '@store/helper'
import { sendTransactionDebit } from '@store/money'
import { moneySelector } from '@store/money/selectors'
import { profileSelector } from '@store/profiles/details/selectors'
import { getTaxes, taxTaxesSlice } from '@store/tax/taxes'
import style from './style.m.less'
import { authSelector } from '@store/auth/selectors'
import { RequisitesCollapse } from './RequisitesCollapse'
import { TaxList } from './TaxList'
import { taxTaxesSelector } from '@store/tax/taxes/selectors'

interface IValues {
    debitSum: string
    debitReason: string
}

export const Debit: FC = () => {
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()

    const user_id = useSelector(authSelector.selectUserUid)
    const profileTypes = useSelector(profileSelector.selectProfileTypes)
    const [isModal, setIsModal] = useState(false)
    const [btnIsDisabled, setBtnIsDisabled] = useState(true)
    const account = useSelector(moneySelector.selectAccount)
    const isPartiallyBlocked = useSelector(profileSelector.selectIsUserBlocked)
    const profileBankAccountDetails = useSelector(profileSelector.selectBankAccountDetails)
    const [amount, setAmount] = useState<number>(0)
    const [fee, setFee] = useState(0)
    const debouncedSearchTerm = useDebounce(amount)
    const isProfilePRSN = profileTypes?.includes(ProfileType.PRSN)
    const calculatedTaxAmount = useSelector(taxTaxesSelector.selectTaxAmountWithoutWithholding)

    const [total, setTotal] = useState(0)

    const formValuesChange = (_, values: IValues) => {
        if (!stringToNumber(values?.debitSum)) {
            return setBtnIsDisabled(true)
        }
        setAmount(stringToNumber(values?.debitSum))
        const debitAmount = stringToNumber(values?.debitSum) * 100
        const fees = calculatedTaxAmount + fee
        const total = debitAmount - fees
        const balanceError = total > (account?.balance.available as number) || total <= 0
        return setBtnIsDisabled(balanceError)
    }

    useEffect(() => {
      setTotal(Math.max(((amount * 100) - calculatedTaxAmount - fee), 0))
    }, [amount, fee])

    const onCloseModal = () => {
        setIsModal(false)
        form.resetFields()
        setBtnIsDisabled(true)
    }

    const sendDebit = (values: IValues) => {
        reduxDispatch(
            sendTransactionDebit({
                amount: getPennies(values.debitSum),
                purpose: values.debitReason,
            }),
        )
        form.resetFields()
        onCloseModal()
    }

    const getFee = async () => {
        const paymentFee = await getCalculateFeeHelper({
            user_id,
            service_code: ServiceCodeFee.paymentOrder,
            operation_amount: amount * 100,
        })

        setFee(paymentFee.item.FeeAmount)
    }


    useEffect(() => {
        if (isModal) {
          getFee()
        }
    }, [isModal])

    useEffect(() => {
      if (isProfilePRSN) {
        reduxDispatch(taxTaxesSlice.actions.setParams({ user_id, ['tax_period.is_active']: true }))
        reduxDispatch(getTaxes())
      }
    }, [isProfilePRSN])

    useEffect(() => {
        if (!debouncedSearchTerm) {
            return
        }
        getFee()
    }, [debouncedSearchTerm])

    return (
        <>
            <Button
                onClick={() => setIsModal(true)}
                borderRadius={12}
                disabled={isPartiallyBlocked}
                tooltipOnDisable="Действие заблокировано. Обратитесь в службу поддержки."
            >
                Вывести
            </Button>
            <Modal visible={isModal} width={600} onCancel={onCloseModal} className={style.modal}>
                <div className={style.wrapper}>
                    <div className={style.header}>Поручение на вывод денежных средств</div>
                    <Box margin={[0, 0, 25, 0]}>
                      <MoneyAccountBlock
                        accountNumber={account?.number}
                        balance={account?.balance.available}
                        title="Счёт списания"
                      />
                    </Box>
                    <div className={style.body}>
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={formValuesChange}
                            onFinish={sendDebit}
                        >
                            <CurrencyInput
                                label="Сумма списания, ₽"
                                name="debitSum"
                                required
                                rules={[
                                    {
                                        validator: account?.balance
                                            ? moneyValidators.debitSum({
                                                  max: account.balance.available / 100,
                                                  min: (calculatedTaxAmount + fee) / 100
                                              })
                                            : undefined,
                                    },
                                ]}
                                size="large"
                            />
                        </Form>
                          <TaxList 
                            fee={(fee || 0)} 
                            taxAmount={calculatedTaxAmount} 
                            calculatedTaxAmount={calculatedTaxAmount}
                            amount={amount * 100}
                            total={total}
                            showTaxInfo={isProfilePRSN}
                          />
                        {Boolean(profileBankAccountDetails) && (
                          <Box margin={[25, 0, 0]}>
                            <RequisitesCollapse profileBankAccountDetails={profileBankAccountDetails}/>
                          </Box>
                        )}
                    </div>
                    <div className={style.footer}>
                        <Button
                            style={{ marginRight: 16, borderRadius: 12 }}
                            onClick={onCloseModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            borderRadius={16}
                            type="primary"
                            disabled={btnIsDisabled}
                            onClick={form.submit}
                        >
                            Отправить поручение
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
