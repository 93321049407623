import { TransactionRow } from '@dltru/dfa-models'
import { BalanceGroup, Box, TransactionDetailsModal, downLoadFile } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import {
    loadsetAccountMoneyByUser,
    loadsetReservesByAccount,
    loadsetTransactionsByAccount,
} from '@store/money'
import { moneySelector } from '@store/money/selectors'

import { BalanceHeader } from './components/BalanceHeader/BalanceHeader'
import { BalanceListHeader } from './components/BalanceList/BalanceListHeader'

import api from '@services/api'

export const Balance: FC = () => {
    const reduxDispatch = useDispatch()
    const userUid = useSelector(authSelector.selectUserUid)
    const account = useSelector(moneySelector.selectAccount)
    const transactions = useSelector(moneySelector.selectTransactions)
    const [selectedRow, setSelectedRow] = useState<TransactionRow>()

    useEffect(() => {
        if (userUid) {
            reduxDispatch(loadsetAccountMoneyByUser())
        }
    }, [userUid])

    useEffect(() => {
        if (account?.id) {
            reduxDispatch(loadsetTransactionsByAccount(account.id))
            reduxDispatch(loadsetReservesByAccount(account.id))
        }
    }, [account?.id])

    return (
        <>
            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <BalanceHeader />
                <Box padding={[24, 32]} className="tablebox tablebox--inner">
                    <BalanceListHeader />
                    {transactions?.map((groupData) => (
                        <BalanceGroup
                            key={groupData.summary.dateStr}
                            {...groupData}
                            onSelect={setSelectedRow}
                        />
                    ))}
                </Box>
            </Box>
            <TransactionDetailsModal
                isModal={Boolean(selectedRow)}
                onClose={() => setSelectedRow(undefined)}
                data={selectedRow}
                wallet={account?.number?.toString() ?? ''}
                onDownloadFile={(uid: string) => {
                    downLoadFile(uid, api.lib.getBase64FileService)
                }}

            />
        </>
    )
}
