import { Divider, MoneyNominalAccount, Space, getRublesFromPennies } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'

import { Debit } from '../Debit/Debit'
import InfoWidget from '../InfoWidget/InfoWidget'
import Refill from '../Refill/Refill'
import './style.less'

export interface IBalanceHeader {
    onAdd?: () => void
    onWithdrawal?: () => void
}

export const BalanceHeader: FC<IBalanceHeader> = () => {
    const balanceInfo = useSelector(moneySelector.selectAccount)

    return (
        <div className="balance-header">
            <MoneyNominalAccount balanceInfo={balanceInfo ?? undefined} />
            <div className="widgets-wrapper">
                <InfoWidget
                    label="Свободных средств"
                    value={getRublesFromPennies(balanceInfo?.balance.available)}
                    special=" ₽"
                />
                <Divider type="vertical" margin={0} />
                <InfoWidget
                    label="Зарезервировано"
                    value={getRublesFromPennies(balanceInfo?.balance.reserved)}
                    special=" ₽"
                />
                <Divider type="vertical" margin={0} />
                <InfoWidget
                    label="Всего"
                    value={getRublesFromPennies(balanceInfo?.balance.total)}
                    special=" ₽"
                />
            </div>
            <Space align="end">
                <Refill />
                <Debit />
            </Space>
        </div>
    )
}
